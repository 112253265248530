


























import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import WordCloud from '@/components/WordCloud.vue'
import { getHighlightText } from '@/utils/common'

export default defineComponent({
    components: {
        WordCloud
    },
    setup() {
        onMounted(() => {
            showButton.value = true
        })
        const showButton = ref(false)

        const list = ref([
            {
                name: '地方',
                value: 1
            },
            {
                name: '外卖',
                value: 1
            },
            {
                name: '大赞',
                value: 1
            },
            {
                name: '地方',
                value: 1
            },
            {
                name: '外卖',
                value: 1
            },
            {
                name: '大赞',
                value: 1
            },
            {
                name: '地方',
                value: 1
            },
            {
                name: '外卖',
                value: 1
            },
            {
                name: '大赞',
                value: 1
            },
            {
                name: '套餐',
                value: 1
            },
            {
                name: '小朋友',
                value: 1
            },
            {
                name: '小酥肉',
                value: 1
            },
            {
                name: '干碟',
                value: 1
            },
            {
                name: '店里',
                value: 1
            },
            {
                name: '很不错',
                value: 1
            },
            {
                name: '很好',
                value: 1
            },
            {
                name: '总体',
                value: 1
            },
            {
                name: '慢一点',
                value: 1
            },
            {
                name: '时间',
                value: 1
            },
            {
                name: '有趣',
                value: 1
            },
            {
                name: '毛肚',
                value: 1
            },
            {
                name: '水果',
                value: 1
            },
            {
                name: '火候',
                value: 1
            },
            {
                name: '火锅',
                value: 1
            },
            {
                name: '火锅店',
                value: 1
            },
            {
                name: '炸物',
                value: 1
            },
            {
                name: '牛腩',
                value: 1
            },
            {
                name: '独特',
                value: 1
            },
            {
                name: '福音',
                value: 1
            },
            {
                name: '第一',
                value: 1
            },
            {
                name: '类型',
                value: 1
            },
            {
                name: '美观',
                value: 1
            },
            {
                name: '肥牛',
                value: 1
            },
            {
                name: '臭豆腐',
                value: 1
            },
            {
                name: '花菜',
                value: 1
            },
            {
                name: '菌菇汤',
                value: 1
            },
            {
                name: '菜品',
                value: 1
            },
            {
                name: '辣椒酱',
                value: 1
            },
            {
                name: '适合',
                value: 1
            },
            {
                name: '锅底',
                value: 1
            },
            {
                name: '食欲',
                value: 1
            },
            {
                name: '麻辣牛肉',
                value: 2
            },
            {
                name: '火候',
                value: 2
            },
            {
                name: '火锅',
                value: 1
            },
            {
                name: '火锅店',
                value: 3
            },
            {
                name: '炸物',
                value: 2
            }
        ])
        const onChange = () => {
            list.value.push({
                name: 'Acrylic Resin',
                value: 4
            })
        }
        const wordCloudInfo = reactive({
            clickWord: (params: any) => {
                console.log(params)
            }
        })
        return {
            list,
            onChange,
            getHighlightText,
            wordCloudInfo,
            showButton
        }
    }
})
